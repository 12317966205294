import styled, { css } from 'styled-components';

interface IBaseTypographProps {
  readonly size: number;
  readonly letterSpacing?: number;
  readonly lineHeight?: number;
  readonly color?: string;
  readonly bold?: boolean;
  readonly underline?: boolean;
  readonly italic?: boolean;
  readonly opacity?: number;
  readonly uppercase?: boolean;
  readonly lowercase?: boolean;
  readonly weak?: boolean;
  readonly pointer?: boolean;
}

const BaseTypographH1 = styled.h1<IBaseTypographProps>`
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ weak }) =>
    weak &&
    css`
      font-weight: 500;
    `}

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: ${`${letterSpacing}px`};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${`${lineHeight}px`};
    `}
    
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

    ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}
`;

const BaseTypographH2 = styled.h2<IBaseTypographProps>`
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ weak }) =>
    weak &&
    css`
      font-weight: 500;
    `}

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: ${`${letterSpacing}px`};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${`${lineHeight}px`};
    `}
    
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

    ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}
`;

const BaseTypographH3 = styled.h3<IBaseTypographProps>`
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ weak }) =>
    weak &&
    css`
      font-weight: 500;
    `}

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: ${`${letterSpacing}px`};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${`${lineHeight}px`};
    `}
    
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

    ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}
`;

const BaseTypographSpan = styled.span<IBaseTypographProps>`
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ weak }) =>
    weak &&
    css`
      font-weight: 500;
    `}

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: ${`${letterSpacing}px`};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${`${lineHeight}px`};
    `}
    
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

    ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}
`;

const BaseTypographP = styled.p<IBaseTypographProps>`
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ weak }) =>
    weak &&
    css`
      font-weight: 500;
    `}

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: ${`${letterSpacing}px`};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${`${lineHeight}px`};
    `}
    
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

    ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}
`;

const BaseTypographSmall = styled.small<IBaseTypographProps>`
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}

  ${({ weak }) =>
    weak &&
    css`
      font-weight: 500;
    `}

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: ${`${letterSpacing}px`};
    `}

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${`${lineHeight}px`};
    `}
    
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

    ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

    ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}
`;

export {
  BaseTypographH1,
  BaseTypographH2,
  BaseTypographH3,
  BaseTypographSpan,
  BaseTypographP,
  BaseTypographSmall,
};
